window.getNumberOfVisibleRows = function (grid) {
    if (grid == null)
        return;
    return Math.floor(grid.getEl().selectNode('.x-grid-body', false).getHeight() / 33);
};

Ext.define('Ext.ux.grid.GridPanelExdMethodExtension', {
    override: 'Ext.ux.grid.GridPanelExd',
    getDefGridQueryCSF: function (params) {
        params = params || {};
        params.colsEx = params.colsEx || [];
        params.colsExRen = params.colsExRen || [];
        params.colsIn = params.colsIn || [];
        if (this.store.filters)
            Ext.apply(params, {
                filters: this.encodeFilters(this.store.getFilters().items)
            });
        else
            Ext.apply(params, {
                filters: []
            });
        if (this.store.sorters) {
            params["sorters"] = this.encodeSorters(this.store.getSorters().items);
        }
        Ext.applyIf(params, this.store.baseParams);
        params.cols = [];
        params.names = [];
        var columns = this.getColumns();
        for (var i = 0; i < columns.length; i++) {
            var cc = columns[i];
            if (!cc.isHidden() && !Ext.Array.contains(params.colsEx, cc.dataIndex) && !Ext.Array.contains(params.colsEx, cc.id) && (params.colsIn.length == 0 || Ext.Array.contains(params.colsIn, cc.dataIndex) || Ext.Array.contains(params.colsIn, cc.id))) {
                if (!cc.hideFromExport) {
                    params.cols.push(cc.dataIndex2 || cc.dataIndex);
                    params.names.push(cc.config.header2 || cc.config.header);
                }
            }
        }
        return params;
    },
    getDefGridDataCSF: function (params) {
        params = this.getDefGridQueryCSF(params);
        var rowsc = this.store.getCount();
        var columns = this.getColumns();
        params.data = [];
        for (var i = 0; i < rowsc; i++) {
            var row = [];
            for (var j = 0; j < columns.length; j++) {
                var cc = columns[j];
                if (!cc.isHidden() && !Ext.Array.contains(params.colsEx, cc.dataIndex) && !Ext.Array.contains(params.colsEx, cc.id) && (params.colsIn.length == 0 || Ext.Array.contains(params.colsIn, cc.dataIndex) || Ext.Array.contains(params.colsIn, cc.id))) {
                    var di = cc.dataIndex;
                    var dataa = this.store.getAt(i).get(di);
                    if (cc.renderer && params.useRenderer && !Ext.Array.contains(params.colsExRen, cc.dataIndex) && !Ext.Array.contains(params.colsExRen, cc.id)) {
                        var dataao = dataa;
                        dataa = cc.renderer(dataa, {}, this.store.getAt(i), i, j, this.store);
                        dataa = Ext.util.Format.stripTags(dataa);
                        if (dataa != null && dataao != null && dataa.toString && dataao.toString) {
                            if (dataa.toString().trim() == "") {
                                dataa = dataao.toString().trim();
                            }
                        } else {
                            dataa = dataao;
                        }
                    }
                    if (Ext.isDate(dataa)) {
                        dataa = dataa.format(Date.patterns.ISO8601Long).trim();
                    } else if (Ext.isObject(dataa)) {
                        dataa = dataa.toString().trim();
                    } else if (Ext.isString(dataa)) {
                        dataa = dataa.trim();
                    }
                    if (params.fnd)
                        dataa = params.fnd.call(params.scope || window, dataa, di, {}, this.store.getAt(i), i, j, this.store);
                    row.push(dataa == undefined ? null : dataa);
                }
            }
            params.data.push(row);
        }
        return params;
    },
    getDefGridDataCSF2: function (params, records) {
        params = this.getDefGridQueryCSF(params);
        var rowsc = records.length;
        var columns = this.getColumns();
        params.data = [];
        for (var i = 0; i < rowsc; i++) {
            var row = [];
            for (var j = 0; j < columns.length; j++) {
                var cc = columns[j];
                if (!cc.isHidden() && !Ext.Array.contains(params.colsEx, cc.dataIndex) && !Ext.Array.contains(params.colsEx, cc.id) && (params.colsIn.length == 0 || Ext.Array.contains(params.colsIn, cc.dataIndex) || Ext.Array.contains(params.colsIn, cc.id))) {
                    var di = cc.dataIndex;
                    var dataa = records[i].get(di);
                    if (cc.renderer && params.useRenderer && !Ext.Array.contains(params.colsExRen, cc.dataIndex) && !Ext.Array.contains(params.colsExRen, cc.id)) {
                        var dataao = dataa;
                        dataa = cc.renderer(dataa, {}, records[i], i, j, null);
                        dataa = Ext.util.Format.stripTags(dataa);
                        if (dataa != null && dataao != null && dataa.toString && dataao.toString) {
                            if (dataa.toString().trim() == "") {
                                dataa = dataao.toString().trim();
                            }
                        } else {
                            dataa = dataao;
                        }
                    }
                    if (Ext.isDate(dataa)) {
                        dataa = dataa.format(Date.patterns.ISO8601Long).trim();
                    } else if (Ext.isObject(dataa)) {
                        dataa = dataa.toString().trim();
                    } else if (Ext.isString(dataa)) {
                        dataa = dataa.trim();
                    }
                    if (params.fnd)
                        dataa = params.fnd.call(params.scope || window, dataa, di, {}, records[i], i, j, null);
                    row.push(dataa == undefined ? null : dataa);
                }
            }
            params.data.push(row);
        }
        return params;
    },
    encodeSorters: function (sorters, preventArray) {
        var out = [],
            length = sorters.length,
            i;
        for (i = 0; i < length; i++) {
            out[i] = sorters[i].serialize();
        }
        return Ext.encode(preventArray ? out[0] : out);
    },
    encodeFilters: function (filters) {
        var out = [],
            length = filters.length,
            encode, i;
        for (i = 0; i < length; i++) {
            encode |= filters[i].serializeTo(out);
        }
        return encode ? Ext.encode(out) : out;
    }
});
