Ext.define("App.WebSocketObject", {
    extend: "Ext.util.Observable",
    websocketURL: "ws://127.0.0.1:9874/websocket",
    websocketURLS: "wss://127.0.0.1:9875/websocket",
    websocketURLC: 0,
    autoReconnect: true,
    deferOpenTimeout: 1000,
    sendFrameMessageId: 0,
    receiveFrameMessageId: 0,
    constructor: function (config) {
        this.callParent(arguments);
    },
    open: function () {
        if (this.ws) {
            throw "WebSocketObject already open, illegal state!";
        }
        this.fireEvent("beforeopen", this);
        this.manualClose = false;
        if (this.websocketURLC == 0) {
            this.ws = new WebSocket(this.websocketURLS);
        } else {
            this.ws = new WebSocket(this.websocketURL);
        }
        this.websocketURLC = (this.websocketURLC + 1) % 2;
        this.ws.onopen = Ext.bind(this.onWsOpen, this);
        this.ws.onclose = Ext.bind(this.onWsClose, this);
        this.ws.onerror = Ext.bind(this.onWsError, this);
        this.ws.onmessage = Ext.bind(this.onWsMessage, this);
    },
    close: function () {
        this.manualClose = true;
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    },
    getState: function (asString) {
        if (this.ws) {
            if (this.ws.readyState == 0) {
                return asString ? "opening" : 0;
            } else if (this.ws.readyState == 1) {
                return asString ? "open" : 1;
            } else if (this.ws.readyState == 2) {
                return asString ? "closing" : 2;
            } else if (this.ws.readyState == 3) {
                return asString ? "close" : 3;
            }
            return this.ws.readyState;
        } else {
            return asString ? "empty" : 4;
        }
    },
    onWsOpen: function () {
        this.fireEvent("open", this);
    },
    onWsClose: function () {
        this.fireEvent("close", this);
        this.ws = null;
        if (this.wsPP && this.wsPP.timeoutId) {
            clearTimeout(this.wsPP.timeoutId);
            this.wsPP.timeoutId = 0;
        }
        if (this.wsPP) {
            var wsPP = this.wsPP;
            this.wsPP = null;
            if (wsPP.callback) {
                wsPP.callback.call(wsPP.scopeCallback, this, null, null, 0, false, this.sendFrameMessageId);
            }
        }
        if (!this.manualClose) {
            if (this.autoReconnect) {
                Ext.defer(function () {
                    if (!this.manualClose) {
                        if (this.autoReconnect) {
                            this.open();
                        }
                    }
                }, this.deferOpenTimeout, this);
            }
        }
    },
    onWsError: function () {
        this.fireEvent("error", this);
    },
    onWsMessage: function (evt) {
        this.receiveFrameMessageId++;
        this.fireEvent("message", this, evt.data, evt, this.receiveFrameMessageId, true, this.sendFrameMessageId);
        if (this.wsPP && this.wsPP.timeoutId) {
            clearTimeout(this.wsPP.timeoutId);
            this.wsPP.timeoutId = 0;
        }
        if (this.wsPP) {
            var wsPP = this.wsPP;
            this.wsPP = null;
            if (wsPP.callback) {
                wsPP.callback.call(wsPP.scopeCallback, this, evt.data, evt, this.receiveFrameMessageId, true, this.sendFrameMessageId);
            }
        }
    },
    send: function (message, callback, scopeCallback, timeout) {
        if (this.ws) {
            this.sendFrameMessageId++;
            if (callback) {
                if (this.wsPP && this.wsPP.timeoutId) {
                    clearTimeout(this.wsPP.timeoutId);
                    this.wsPP.timeoutId = 0;
                }
                this.wsPP = {
                    callback: callback,
                    scopeCallback: scopeCallback,
                    timeoutId: 0
                };
                if (timeout) {
                    this.wsPP.timeoutId = Ext.defer(function () {
                        if (this.wsPP) {
                            var wsPP = this.wsPP;
                            this.wsPP = null;
                            if (wsPP.callback) {
                                wsPP.callback.call(wsPP.scopeCallback, this, null, null, 0, false, this.sendFrameMessageId);
                            }
                        }
                    }, timeout, this);
                }
            }
            if (!Ext.isString(message)) {
                message = Ext.encode(message);
            }
            this.ws.send(message);
            return true;
        } else {
            if (this.wsPP && this.wsPP.timeoutId) {
                clearTimeout(this.wsPP.timeoutId);
                this.wsPP.timeoutId = 0;
            }
            if (this.wsPP) {
                var wsPP = this.wsPP;
                this.wsPP = null;
                if (wsPP.callback) {
                    wsPP.callback.call(wsPP.scopeCallback, this, null, null, 0, false, this.sendFrameMessageId);
                }
            }
            return false;
        }
    }
});
