if (Ext.Date) {
    Ext.Date.monthNames = [Lang.styczen, Lang.luty, Lang.marzec, Lang.kwiecien, Lang.maj, Lang.czerwiec, Lang.lipiec, Lang.sierpien, Lang.wrzesien, Lang.pazdziernik, Lang.listopad, Lang.grudzien];

    Ext.Date.getShortMonthName = function (month) {
        return Ext.Date.monthNames[month].substring(0, 3);
    };

    Ext.Date.monthNumbers = {};
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(0)] = 0;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(1)] = 1;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(2)] = 2;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(3)] = 3;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(4)] = 4;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(5)] = 5;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(6)] = 6;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(7)] = 7;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(8)] = 8;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(9)] = 9;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(10)] = 10;
    Ext.Date.monthNumbers[Ext.Date.getShortMonthName(11)] = 11;

    Ext.Date.getMonthNumber = function (name) {
        return Ext.Date.monthNumbers[name.substring(0, 1).toUpperCase() + name.substring(1, 3).toLowerCase()];
    };

    Ext.Date.dayNames = [Lang.niedziela, Lang.poniedzialek, Lang.wtorek, Lang.sroda, Lang.czwartek, Lang.piatek, Lang.sobota];

    Ext.Date.getShortDayName = function (day) {
        switch (day) {
            case 0:
                return Lang.niedzielas;
            case 1:
                return Lang.poniedzialeks;
            case 2:
                return Lang.wtoreks;
            case 3:
                return Lang.srodas;
            case 4:
                return Lang.czwarteks;
            case 5:
                return Lang.piateks;
            case 6:
                return Lang.sobotas;
            default:
                return '';
        }
    };
}

if (Ext.view.View) {
    Ext.view.View.prototype.emptyText = "";
}

if (Ext.grid.plugin.DragDrop) {
    Ext.grid.plugin.DragDrop.prototype.dragText = Lang.n_wybrano_wiersze_y;
}

if (Ext.LoadMask) {
    Ext.LoadMask.prototype.msg = Lang.przetwarzanie_danych;
}

if (Ext.view.AbstractView) {
    Ext.view.AbstractView.prototype.loadingText = Lang.wczytywanie_danych;
}

if (Ext.picker.Date) {
    Ext.apply(Ext.picker.Date.prototype, {
        todayText: Lang.dzisiaj,
        minText: Lang.data_jest_wczesniejsza_niz_min,
        maxText: Lang.data_jest_pozniejsza_niz_max,
        monthNames: Ext.Date.monthNames,
        dayNames: Ext.Date.dayNames,
        nextText: Lang.nastepny_miesiac,
        prevText: Lang.poprzedni_miesiac,
        monthYearText: Lang.wybierz_miesiac
    });
}

if (Ext.picker.Month) {
    Ext.apply(Ext.picker.Month.prototype, {
        okText: Lang.lokl,
        cancelText: Lang.anuluj
    });
}

if (Ext.PagingToolbar) {
    Ext.apply(Ext.PagingToolbar.prototype, {
        beforePageText: Lang.strona,
        afterPageText: Lang.z_zero,
        firstText: Lang.pierwsza_strona,
        prevText: Lang.poprzednia_strona,
        nextText: Lang.nastepna_strona,
        lastText: Lang.ostatnia_strona,
        refreshText: Lang.odswiez,
        displayMsg: Lang.wyswietlono_0_1_2,
        emptyMsg: Lang.brak_danych_do_wyswietlenia
    });
}

if (Ext.form.field.Basic) {
    Ext.form.field.Basic.prototype.waitTitle = Lang.prosze_czekac;
}

if (Ext.form.field.Base) {
    Ext.form.field.Base.prototype.invalidText = Lang.wartosc_tego_pola_jest_niewlasciwa;
}

if (Ext.form.field.Text) {
    Ext.apply(Ext.form.field.Text.prototype, {
        minLengthText: Lang.minimalna_ilosc_znakow_dla_tego_pola,
        maxLengthText: Lang.maksymalna_ilosc_znakow_dla_tego_pola,
        blankText: Lang.to_pole_jest_wymagane,
        regexText: "",
        emptyText: null
    });
}

if (Ext.form.field.Number) {
    Ext.apply(Ext.form.field.Number.prototype, {
        minText: Lang.minimalna_wartosc_dla_tego_pola,
        maxText: Lang.maksymalna_wartosc_dla_tego_pola,
        nanText: Lang.value_to_nie_jest_wlasciwa_wartosc
    });
}

if (Ext.form.field.Date) {
    Ext.apply(Ext.form.field.Date.prototype, {
        disabledDaysText: Lang.wylaczony,
        disabledDatesText: Lang.wylaczony,
        minText: Lang.data_w_tym_polu_musi_byc_pozniejsza_od_0,
        maxText: Lang.data_w_tym_polu_musi_byc_wczesniejsza_od_0,
        invalidText: Lang.value_to_nie_jest_prawidlowa_data_prawidlowy_format_daty,
        formatText: Lang.oczekiwany_format_daty
    });
}

if (Ext.form.field.ComboBox) {
    Ext.apply(Ext.form.field.ComboBox.prototype, {
        valueNotFoundText: undefined
    });
    Ext.apply(Ext.form.field.ComboBox.prototype.defaultListConfig, {
        loadingText: Lang.wczytuje
    });
}

if (Ext.form.field.VTypes) {
    Ext.apply(Ext.form.field.VTypes.prototype, {
        emailText: Lang.to_pole_wymaga_podania_adresu_emial_w_formacie,
        urlText: Lang.to_pole_wymaga_podania_adresu_strony_w_formacie,
        alphaText: Lang.to_pole_wymaga_tylko_liter_,
        alphanumText: Lang.to_pole_wymaga_tylko_liter_i_cyfr_
    });
}

if (Ext.grid.header.Container) {
    Ext.apply(Ext.grid.header.Container.prototype, {
        sortAscText: Lang.sortuj_rosnaco,
        sortDescText: Lang.sortuj_malejaco,
        columnsText: Lang.kolumny
    });
}

if (Ext.grid.feature.Grouping) {
    Ext.apply(Ext.grid.feature.Grouping.prototype, {
        emptyGroupText: Lang.none,
        groupByText: Lang.grupuj_po_tym_polu,
        showGroupsText: Lang.pokaz_w_grupach,
        collapseTip: Lang.grupy_collapse_tooltip
    });
}

if (Ext.grid.PropertyColumnModel) {
    Ext.apply(Ext.grid.PropertyColumnModel.prototype, {
        nameText: Lang.nazwa,
        valueText: Lang.wartosc,
        trueText: Lang.tak,
        falseText: Lang.nie
    });
}

if (Ext.grid.BooleanColumn) {
    Ext.apply(Ext.grid.BooleanColumn.prototype, {
        trueText: Lang.tak,
        falseText: Lang.nie
    });
}

if (Ext.form.field.Time) {
    Ext.apply(Ext.form.field.Time.prototype, {
        minText: Lang.data_w_tym_polu_musi_byc_pozniejsza_od_0,
        maxText: Lang.data_w_tym_polu_musi_byc_wczesniejsza_od_0,
        invalidText: Lang.value_to_nie_jest_prawidlowa_data_prawidlowy_format_daty
    });
}

if (Ext.form.field.File) {
    Ext.apply(Ext.form.field.File.prototype, {
        buttonText: Lang.wybierz_file
    });
}

if (Ext.form.CheckboxGroup) {
    Ext.apply(Ext.form.CheckboxGroup.prototype, {
        blankText: Lang.musisz_wybrac_przynajmniej_jedna_wartosc_w_grupie
    });
}

if (Ext.form.RadioGroup) {
    Ext.apply(Ext.form.RadioGroup.prototype, {
        blankText: Lang.musisz_wybrac_przynajmniej_jedna_wartosc_w_grupie
    });
}

if (Ext.window.Window) {
    Ext.apply(Ext.window.Window.prototype, {
        closeToolText: Lang.zamknij
    });
}

if (Ext.window.MessageBox) {
    Ext.apply(Ext.window.MessageBox.prototype, {
        buttonText: {
            ok: Lang.ok,
            cancel: Lang.anuluj,
            yes: Lang.tak,
            no: Lang.nie
        }
    });
}

if (Ext.grid.filters.Filters) {
    Ext.grid.filters.Filters.prototype.menuFilterText = Lang.filtrowanie;
}

if (Ext.grid.filters.filter.Boolean) {
    Ext.apply(Ext.grid.filters.filter.Boolean.prototype, {
        yesText: Lang.tak,
        noText: Lang.nie
    });
}

if (Ext.grid.filters.filter.Date) {
    Ext.override(Ext.grid.filters.filter.Date, {
        config: {
            fields: {
                lt: {
                    text: Lang.przed
                },
                gt: {
                    text: Lang.po
                },
                eq: {
                    text: Lang.ww
                }
            }
        }
    });
}

if (Ext.grid.filters.filter.List) {
    Ext.apply(Ext.grid.filters.filter.List.prototype, {
        loadingText: Lang.ladowanie_proccess
    });
}

if (Ext.grid.filters.filter.Number) {
    Ext.apply(Ext.grid.filters.filter.Number.prototype, {
        emptyText: Lang.wprowadz_wartosc
    });
}

if (Ext.grid.filters.filter.String) {
    Ext.apply(Ext.grid.filters.filter.String.prototype, {
        emptyText: Lang.wprowadz_wartosc
    });
}

if (Ext.Updater) {
    Ext.Updater.defaults.indicatorText = '<div class="loading-indicator">' + Lang.wczytywanie_danych + '</div>';
}

if (Ext.panel.Panel) {
    Ext.apply(Ext.panel.Panel.prototype, {
        collapseToolText: Lang.zwin,
        expandToolText: Lang.rozwin
    });
}

if (Ext.tab.Tab) {
    Ext.tab.Tab.prototype.closeText = Lang.zamknij_zakladke;
}

if (Ext.ux.form.field.FileExd) {
    Ext.apply(Ext.ux.form.field.FileExd.prototype, {
        text_multiple: Lang.plikow_wybranych,
        buttonText: Lang.przegladaj + '...'
    });
}

if (Ext.ux.form.field.DisplayExd) {
    Ext.apply(Ext.ux.form.field.DisplayExd.prototype, {
        textTrue: Lang.tak,
        textFalse: Lang.nie
    });
}

if (Ext.ux.form.field.FieldLabelExd) {
    Ext.apply(Ext.ux.form.field.FieldLabelExd.prototype, {
        textTrue: Lang.tak,
        textFalse: Lang.nie
    });
}

if (Ext.ux.form.field.LabelExd) {
    Ext.apply(Ext.ux.form.field.LabelExd.prototype, {
        textTrue: Lang.tak,
        textFalse: Lang.nie
    });
}
