Ext.define('PagingToolbarExd', {
    extend: 'Ext.toolbar.Paging',
    alias: 'widget.pagingtoolbarexd',
    pagingStore: null,
    statics: {
        values: [10, 20, 30, 40, 50, 100, 200, 1000]
    },
    initComponent: function () {
        this.callParent(arguments);
        this.insert(11, {
            xtype: 'comboexd',
            store: PagingToolbarExd.values,
            value: this.pagingStore.getPageSize(),
            listeners: {
                scope: this,
                select: this.onComboBoxSelect
            }
        });
        this.comboBox = this.items.getAt(11);
    },
    onComboBoxSelect: function (combobox, record) {
        this.pagingStore.setPageSize(record.get("field1"));
        this.pagingStore.load();
    },
    updatePageSize: function (newPageSize) {
        this.comboBox.setValue(newPageSize);
        this.pagingStore.setPageSize(newPageSize);
    }
});
