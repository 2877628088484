Ext.define("App.view.PanelWindowExd", {
    extend: "Ext.Window",
    cls: "panelWindowExd",
    alias: "widget.panelwindowexd",
    modal: false,
    shadow: "frame",
    draggable: false,
    initComponent: function () {
        this.callParent(arguments);
    }
});