Ext.define('Ext.layout.container.MyBootstrapLayout', {
    extend: 'Ext.layout.container.Auto',
    alias: 'layout.myboostraplayout',
    childEls: ['bootstrapwrapper'],
    beforeBodyTpl: '<div class="myboostraplayout-container {bootstrapCtCls}"><div id="{ownerId}-bootstrapwrapper" data-ref="bootstrapwrapper" class="myboostraplayout-row {bootstrapRowCls}">',
    afterBodyTpl: '</div></div>',
    innerCtClsAdd: "",
    itemClsAdd: "myboostraplayout-col",
    bootstrapCtCls: "container-fluid p-0",
    bootstrapRowCls: "row no-gutters",
    initLayout: function () {
        this.innerCtCls += ' ' + this.innerCtClsAdd;
        this.callParent();
    },
    getRenderData: function () {
        var data = this.callParent();
        Ext.apply(data, {"bootstrapCtCls": this.bootstrapCtCls, "bootstrapRowCls": this.bootstrapRowCls});
        return data;
    },
    beginLayout: function (ownerContext) {
        this.callParent([ownerContext]);
    },
    beginLayoutCycle: function (ownerContext, firstCycle) {
        this.callParent([ownerContext, firstCycle]);
    },
    onAdd: function (item) {
        item.addCls(this.itemClsAdd);
        if (item.bootstrapColCls) {
            item.addCls(item.bootstrapColCls);
        } else {
            item.addCls("col");
        }
        this.callParent([item]);
    },
    getItemSizePolicy: function () {
        return {
            readsWidth: 1,
            readsHeight: 0,
            setsWidth: 1,
            setsHeight: 0
        };
    },
    getRenderTarget: function () {
        return this.bootstrapwrapper;
    }
});