window.ImagePreloader = function ImagePreloader(images, scope, tcall_back, tcall_back2) {
    this.call_back = tcall_back;
    this.call_back2 = tcall_back2;
    this.scope = scope;
    this.aImages = [];
    this.nImages = images.length;
    this.nProcessed = 0;
    for (var i = 0; i < images.length; i++)
        this.preload(images[i]);
}

ImagePreloader.prototype.preload = function (image) {
    var oImage = new Image;
    this.aImages.push(oImage);
    oImage.onload = ImagePreloader.prototype.onload;
    oImage.onerror = ImagePreloader.prototype.onerror;
    oImage.onabort = ImagePreloader.prototype.onabort;
    oImage.oImagePreloader = this;
    oImage.bLoaded = false;
    oImage.src = image;
};

ImagePreloader.prototype.onComplete = function () {
    this.nProcessed++;
    if (this.call_back)
        this.call_back.call(this.scope, this.aImages, this.nProcessed, this.nImages);
    if (this.nProcessed == this.nImages) {
        if (this.call_back2)
            this.call_back2.call(this.scope, this.aImages, this.nProcessed, this.nImages);
    }
};

ImagePreloader.prototype.onload = function () {
    this.bLoaded = true;
    this.oImagePreloader.onComplete();
};

ImagePreloader.prototype.onerror = function () {
    this.bError = true;
    this.oImagePreloader.onComplete();
};

ImagePreloader.prototype.onabort = function () {
    this.bAbort = true;
    this.oImagePreloader.onComplete();
};
