Ext.define('PageToolbarOwn', {
    extend: 'Ext.toolbar.Paging',
    alias: 'widget.pagetoolbarown',
    cls: "a-PageToolbarOwn",
    displayInfo: false,
    beforePageText: "",
    afterPageText: "",
    compactMode: true,
    pagingStore: null,
    statics: {
        values: [10, 20, 30, 40, 50, 100, 200, 1000]
    },
    initComponent: function () {
        this.callParent(arguments);
        var icc = this.compactMode ? 5 : 11;
        this.insert(icc, {
            xtype: 'comboexd',
            width: 120,
            store: PagingToolbarExd.values,
            value: this.pagingStore.getPageSize(),
            listeners: {
                scope: this,
                select: this.onComboBoxSelect
            }
        });
        this.comboBox = this.items.getAt(icc);
        if (this.compactMode) {
            this.down("#displayItem").setHidden(true);
        }
    },
    onComboBoxSelect: function (combobox, record) {
        this.pagingStore.setPageSize(record.get("field1"));
        this.pagingStore.load();
    },
    updatePageSize: function (newPageSize) {
        this.comboBox.setValue(newPageSize);
        this.pagingStore.setPageSize(newPageSize);
    },
    getPagingItems: function () {
        var me = this;
        var inputListeners = {
            scope: me,
            blur: me.onPagingBlur
        };
        inputListeners[Ext.supports.SpecialKeyDownRepeat ? 'keydown' : 'keypress'] = me.onPagingKeyDown;
        if (this.compactMode) {
            return [{
                itemId: 'first',
                tooltip: me.firstText,
                overflowText: me.firstText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-first',
                disabled: true,
                handler: me.moveFirst,
                scope: me
            }, {
                itemId: 'prev',
                tooltip: me.prevText,
                overflowText: me.prevText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-prev',
                disabled: true,
                handler: me.movePrevious,
                scope: me
            }, {
                itemId: 'next',
                tooltip: me.nextText,
                overflowText: me.nextText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-next',
                disabled: true,
                handler: me.moveNext,
                scope: me
            }, {
                itemId: 'last',
                tooltip: me.lastText,
                overflowText: me.lastText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-last',
                disabled: true,
                handler: me.moveLast,
                scope: me
            }, {
                itemId: 'refresh',
                tooltip: me.refreshText,
                overflowText: me.refreshText,
                iconCls: Ext.baseCSSPrefix + 'tbar-loading',
                disabled: me.store.isLoading(),
                handler: me.doRefresh,
                scope: me
            }];
        } else {
            return [{
                itemId: 'first',
                tooltip: me.firstText,
                overflowText: me.firstText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-first',
                disabled: true,
                handler: me.moveFirst,
                scope: me
            }, {
                itemId: 'prev',
                tooltip: me.prevText,
                overflowText: me.prevText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-prev',
                disabled: true,
                handler: me.movePrevious,
                scope: me
            }, '-', me.beforePageText, {
                xtype: 'numberfield',
                itemId: 'inputItem',
                name: 'inputItem',
                cls: Ext.baseCSSPrefix + 'tbar-page-number',
                allowDecimals: false,
                minValue: 1,
                hideTrigger: true,
                enableKeyEvents: true,
                keyNavEnabled: false,
                selectOnFocus: true,
                submitValue: false,
                isFormField: false,
                width: me.inputItemWidth,
                margin: '-1 2 3 2',
                listeners: inputListeners
            }, {
                xtype: 'tbtext',
                itemId: 'afterTextItem',
                html: Ext.String.format(me.afterPageText, 1)
            }, '-', {
                itemId: 'next',
                tooltip: me.nextText,
                overflowText: me.nextText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-next',
                disabled: true,
                handler: me.moveNext,
                scope: me
            }, {
                itemId: 'last',
                tooltip: me.lastText,
                overflowText: me.lastText,
                iconCls: Ext.baseCSSPrefix + 'tbar-page-last',
                disabled: true,
                handler: me.moveLast,
                scope: me
            }, '-', {
                itemId: 'refresh',
                tooltip: me.refreshText,
                overflowText: me.refreshText,
                iconCls: Ext.baseCSSPrefix + 'tbar-loading',
                disabled: me.store.isLoading(),
                handler: me.doRefresh,
                scope: me
            }];
        }
    }
});
